<template>
  <Top />
  <div class="section">
    <div v-if="store.user" class="block">
      <h1>
        You are logged in as {{ store.user.name }} ({{ store.user.username }})
      </h1>
      <a @click="mailme">mail me</a>
      <div v-if="adminGroups.length">
        <span>
          You are admin of
          {{ adminGroups.map((a: Group) => a.groupname).join(", ") }}.
        </span>
      </div>
      <div v-if="memberGroups.length">
        <span>
          You are member of
          {{ memberGroups.map((a: Group) => a.groupname).join(", ") }}.
        </span>
      </div>
      <button class="button is-small" :disabled="!online" @click="logout">
        log out
      </button>
    </div>
    <div v-else class="block">
      <h1 class="heading">Login</h1>
      <form onsubmit="return false">
        <label class="label">Username:</label><br />
        <input
          v-model="username"
          class="input"
          type="text"
          name="username"
          placeholder="Username"
        /><br /><br />
        <label class="label">Password:</label><br />
        <input
          v-model="password"
          class="input"
          type="password"
          name="password"
          placeholder="Password"
        /><br /><br />
        <input
          class="button is-small"
          type="submit"
          value="login"
          :disabled="!online"
          @click="login"
        />
      </form>
    </div>
    <div v-if="error" class="block">{{ error }}</div>
    <div class="block">
      <h2 class="title">App info</h2>
      <p>
        home page:
        <a href="https://codeberg.org/vandenoever/rehorse/" target="_blank"
          >https://codeberg.org/vandenoever/rehorse/</a
        >
      </p>
      <p>
        client version:
        <a
          :href="
            'https://codeberg.org/vandenoever/rehorse/releases/tag/v' + version
          "
          target="_blank"
          >{{ version }}</a
        >
        ({{ lastModified.replace("T", " ").substring(0, 16) }},
        <a
          :href="'https://codeberg.org/vandenoever/rehorse/commit/' + revision"
          target="_blank"
        >
          {{ revision.substring(0, 6) }}</a
        >)
      </p>
      <p v-if="serverInfo">
        server version:
        <a
          :href="
            'https://codeberg.org/vandenoever/rehorse/releases/tag/v' +
            serverInfo.version
          "
          target="_blank"
          >{{ serverInfo.version }}</a
        >
        ({{ serverInfo.lastModified.replace("T", " ").substring(0, 16) }},
        <a
          :href="'https://codeberg.org/vandenoever/rehorse/commit/' + revision"
          target="_blank"
        >
          {{ serverInfo.revision.substring(0, 6) }}</a
        >)
      </p>
      <p>online: {{ online }}</p>
      <p>
        <a
          href="https://vite-pwa-org.netlify.app/frameworks/vue.html#prompt-for-update"
          target="_blank"
          >offlineReady</a
        >: {{ offlineReady }}
      </p>
      <p>
        <a
          href="https://vite-pwa-org.netlify.app/frameworks/vue.html#prompt-for-update"
          taget="_blank"
          >needRefresh</a
        >: {{ needRefresh }}
      </p>
      <p>
        <button class="button is-small" @click="updateApp">updateApp</button>
      </p>
      <p>
        <button class="button is-small" @click="updateServiceWorker(true)">
          updateServiceWorker(reloadPage:true)
        </button>
      </p>
      <p>
        <button class="button is-small" @click="updateServiceWorker(false)">
          updateServiceWorker(reloadPage:false)
        </button>
      </p>
    </div>
    <div class="block">
      <router-link :to="{ name: 'cache' }">cache</router-link>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, Ref } from "vue";
import { useRehorseStore } from "../store";
import { version, lastModified, revision } from "../../shared/version.json";
import { useRouter } from "vue-router";
import Top from "../components/Top.vue";
import { Group } from "../../shared/group";
import { online } from "../online";
import {
  offlineReady,
  needRefresh,
  updateServiceWorker,
  updateApp,
} from "../pwa";

interface VersionInfo {
  version: string;
  lastModified: string;
  revision: string;
}

const store = useRehorseStore();
const username = ref("");
const password = ref("");
const error = ref("");
const router = useRouter();
const serverInfo = ref<VersionInfo | undefined>();

const adminGroups: Ref<Group[]> = computed(() => {
  if (!store.groups.isReady) {
    return [];
  }
  const user = store.user?.username;
  return store.groups.data.filter((g) => g.admins.some((u) => u === user));
});
const memberGroups = computed(() => {
  if (!store.groups.isReady) {
    return [];
  }
  const user = store.user?.username;
  return store.groups.data.filter((g) => g.members.some((u) => u === user));
});

async function login() {
  error.value = "";
  try {
    const response = await fetch("login", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        username: username.value,
        password: password.value,
      }),
    });
    if (response.ok) {
      router.go(-1);
    } else {
      error.value = response.statusText + " " + (await response.text());
    }
    store.checkUser();
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      error.value = e.toString();
    }
  }
}

function logout() {
  store.logout();
  router.go(-1);
}
const getServerVersion = async (): Promise<VersionInfo> => {
  const response = await fetch("api/version");
  const server: unknown = await response.json();
  if (
    typeof server === "object" &&
    server !== null &&
    "version" in server &&
    "lastModified" in server &&
    "revision" in server
  ) {
    const serverVersion = server.version;
    const serverLastModified = server.lastModified;
    const serverRevision = server.revision;
    if (
      typeof serverVersion === "string" &&
      typeof serverLastModified === "string" &&
      typeof serverRevision === "string"
    ) {
      return {
        version: serverVersion,
        lastModified: serverLastModified,
        revision: serverRevision,
      };
    }
  }
  console.error(server);
  throw new Error("Could not retrieve server version.");
};
const mailme = () => {
  if (!store.user) {
    return;
  }
  console.log(store.user.email);
};
onMounted(async () => {
  serverInfo.value = await getServerVersion();
});
</script>
